import classNames from 'classnames';
import styles from './LinkButton.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import type { LinkProps } from 'ui/components/1-atoms/Action/Link/Link';

export interface LinkButtonProps extends Omit<LinkProps, 'style'> {
	children: React.ReactNode;
	state?: 'inactive';
	style?: 'primary' | 'secondary' | 'inactive' | 'sm';
	inheritsColor?: boolean;
	className?: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ children, style, inheritsColor, className, state, ...props }) => (
	<Link
		className={classNames(
			styles.LinkButton,
			styles[`LinkButton___${style}`],
			styles[`LinkButton___${state}`],
			{ [styles.LinkButton___inheritsColor]: inheritsColor },
			className,
		)}
		target={props.target}
		href={props.href}
		{...props}
	>
		<div className={styles.LinkButton_textContainer}>
			<span className={styles.LinkButton_text}>{children}</span>
			<span className={classNames(styles.LinkButton_text, styles.LinkButton_text___animationText, className)}>{children}</span>
		</div>
	</Link>
);
