import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import type { ServiceLinkCardProps } from 'ui/components/2-molecules/Card/ServiceLinkCard/ServiceLinkCard';
import styles from './ServiceLinkList.module.scss';
import { ServiceLinkCard } from 'ui/components/2-molecules/Card/ServiceLinkCard/ServiceLinkCard';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import { Container } from 'ui/components/4-habitats/Container/Container';
import classNames from 'classnames';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import ColorOptions from 'helpers/types';
import { LinkButton } from 'ui/components/1-atoms/Action/LinkButton/LinkButton';

export interface ServiceLinkListProps {
	title?: string;
	items: ServiceLinkCardProps[];
	link?: {
		url: string;
		target?: string;
		name?: string;
	};
	kicker?: KickerProps;
	bgColor?: ColorOptions;
}

export const ServiceLinkList: React.FC<ServiceLinkListProps> = ({ items, link, title, kicker, bgColor }) => {
	return (
		<div className={classNames(styles.ServiceLinkList, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<TopLevelGrid wrap container>
					<TopLevelGridCell desktopWidth="16col" mobileWidth="100">
						{kicker && <Kicker {...kicker} className={classNames(styles.ServiceLinkList_kicker, 'u-bottom-padding--xxl')} />}
						{title && (
							<Heading headingLevel="h2" style="xl" className={styles.ServiceLinkList_heading}>
								{title}
							</Heading>
						)}
					</TopLevelGridCell>
					<TopLevelGridCell desktopWidth="15col" desktopOffset="2col" offsetDirection="right" mobileWidth="100" className={styles.ServiceLinkList_grid}>
						<Grid wrap={true}>
							{items.map((item, index) => (
								<GridCell desktopWidth="50" mobileWidth="100" key={index} className={styles.ServiceLinkList_gridCell}>
									<ServiceLinkCard {...item} key={index} className={styles.ServiceLinkList_card} />
								</GridCell>
							))}
							{link && (
								<GridCell desktopWidth="50" mobileWidth="100" className={styles.ServiceLinkList_btnContainer}>
									<LinkButton
										style="primary"
										href={link.url}
										title={link.name}
										target={link.target}
										className={styles.ServiceLinkList_btn}
										inheritsColor
									>
										{link.name}
									</LinkButton>
								</GridCell>
							)}
						</Grid>
					</TopLevelGridCell>
				</TopLevelGrid>
			</Container>
		</div>
	);
};
