import classNames from 'classnames';

export type TopLevelGridColOptions =
	| '0col'
	| '1col'
	| '2col'
	| '3col'
	| '4col'
	| '5col'
	| '6col'
	| '7col'
	| '8col'
	| '9col'
	| '10col'
	| '11col'
	| '12col'
	| '13col'
	| '14col'
	| '15col'
	| '16col'
	| '17col'
	| '18col'
	| '19col'
	| '20col'
	| '21col'
	| '22col'
	| '23col'
	| '24col'
	| '';
export interface TopLevelGridCellProps {
	className?: string;
	children: React.ReactNode;
	center?: boolean;
	offsetDirection?: 'left' | 'right';
	mobileWidth?: '10' | '25' | '33' | '50' | '66' | '75' | '100' | '';
	desktopWidth?: TopLevelGridColOptions;
	desktopOffset?: TopLevelGridColOptions;
	expand?: TopLevelGridColOptions;
}

export const TopLevelGridCell: React.FC<TopLevelGridCellProps> = ({
	className,
	desktopOffset = null,
	offsetDirection = 'left',
	mobileWidth = '100',
	desktopWidth = '100',
	children,
	center,
	expand,
}) => {
	let stretch = null;
	if (desktopWidth && expand) {
		stretch = `u-grid__cell--stretch-${desktopWidth}-${24 - parseInt(expand)}col@md`;
	} else if (desktopWidth && desktopOffset) {
		stretch = `u-grid__cell--stretch-${desktopWidth}-${desktopOffset}@md`;
	} else if (desktopWidth && center) {
		stretch = `u-grid__cell--stretch-${desktopWidth}-0col@md`;
	}
	return (
		<div
			className={classNames(
				'u-grid__cell',
				'u-grid__cell--width-100',
				{ [`u-grid__cell--width-${mobileWidth}@xs`]: mobileWidth },
				{ [`u-grid__cell--width-${desktopWidth}@md`]: desktopWidth },
				{ [`u-grid__cell--offsetLeft-${desktopOffset}@md`]: desktopOffset && offsetDirection === 'left' },
				{ [`u-grid__cell--offsetRight-${desktopOffset}@md`]: desktopOffset && offsetDirection === 'right' },
				{ [stretch]: stretch },
				{ 'u-grid__cell--center': center },
				className,
			)}
		>
			{children}
		</div>
	);
};
