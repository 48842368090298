import classNames from 'classnames';
import styles from './Kicker.module.scss';

export interface KickerProps {
	className?: string;
	firstWord?: string;
	secondWord?: string;
}

export const Kicker: React.FC<KickerProps> = ({ firstWord, secondWord, className }) => {
	return (
		<div className={classNames(styles.Kicker, className)}>
			{firstWord && <span className={styles.Kicker_kickerFirst}>{firstWord}</span>}
			{secondWord && <span className={styles.Kicker_kickerSecond}>{secondWord}</span>}
		</div>
	);
};
