import classNames from 'classnames';
import styles from './TopLevelGrid.module.scss';

export interface TopLevelGridProps {
	className?: string;
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	largeGutter?: boolean;
	center?: boolean;
	horizontalCenter?: boolean;
	full?: boolean;
	children?: React.ReactNode;
	spaceBetween?: boolean;
	scaleDown?: boolean;
}

export const TopLevelGrid: React.FC<TopLevelGridProps> = ({
	wrap,
	container,
	nogutter,
	largeGutter,
	center,
	horizontalCenter,
	full,
	children,
	className,
	spaceBetween,
	scaleDown,
}) => (
	<div
		className={classNames(
			styles.TopLevelGrid,
			{ [styles.TopLevelGrid___wrap]: wrap },
			{ [styles.TopLevelGrid___container]: container },
			{ [styles.TopLevelGrid___noGutter]: nogutter },
			{ [styles.TopLevelGrid___largeGutter]: largeGutter },
			{ [styles.TopLevelGrid___center]: center },
			{ [styles.TopLevelGrid___full]: full },
			{ [styles.TopLevelGrid___horizontalCenter]: horizontalCenter },
			{ [styles.TopLevelGrid___spaceBetween]: spaceBetween },
			{ [styles.TopLevelGrid___scaleDown]: scaleDown },
			// scaleDown should be used when we don't want the grid elements to have the minimum width of the cell in the 1728px width screen,
			// and this allows it to scale down proportionally to the screen size
			// so, use scaleDown when you have the grid with preview cards like on insights, news, events pages
			// alternatively, when we don't use scaleDown, the element will keep its minimum width down to mobile breakpoint(or some other breakpoint tbd)
			className,
		)}
	>
		{children}
	</div>
);
