import React from 'react';
import classNames from 'classnames';
import styles from './ServiceLinkCard.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import RightArrow from 'ui/icons/icon-right-button-arrow.svg';
import RightArrowKp from 'ui/icons/icon-right-button-arrow-kp.svg';

export interface ServiceLinkCardProps {
	className?: string;
	globalTheme?: string;
	heading: string;
	kicker?: KickerProps;
	link?: {
		url: string;
		target?: string;
		name?: string;
	};
	text?: string;
}

export const ServiceLinkCard: React.FC<ServiceLinkCardProps> = ({ className, globalTheme, heading, kicker, link, text }) => {
	const hasKicker = kicker?.firstWord || kicker?.secondWord;
	return (
		<div
			className={classNames(
				styles.ServiceLinkCard,
				{ [styles.ServiceLinkCard___withText]: text != null },
				{ [styles.ServiceLinkCard___withoutLink]: link === null },
				{ [styles.ServiceLinkCard___withKicker]: hasKicker },
				className,
			)}
		>
			<Link noUnderline className={styles.ServiceLinkCard_link} href={link?.url} target={link?.target} title={link?.name}>
				{hasKicker && <Kicker {...kicker} className={styles.ServiceLinkCard_kicker} />}
				<Heading headingLevel="h3" className={styles.ServiceLinkCard_heading}>
					{heading}
					{link && (
						<Icon size={text ? 'md' : 'lg'} className={styles.ServiceLinkCard_linkIcon}>
							{globalTheme === 'kogp' ? <RightArrowKp /> : <RightArrow />}
						</Icon>
					)}
				</Heading>
				{text && <p className={styles.ServiceLinkCard_text}>{text}</p>}
			</Link>
		</div>
	);
};
